<template>
	<el-dialog title="编辑账号"  @close="reset" :close-on-click-modal="false" :visible.sync="dialogVisible">
		
		<el-form :model="accountData" class="form-con-center4" :rules="dataRule" ref="accountData" label-width="85px">
			 
			<el-form-item label="姓名" required>
				<el-input v-model="accountData.name"></el-input>
			</el-form-item>
			<el-form-item label="手机号" required>
				<el-input v-model="accountData.phone"></el-input>
			</el-form-item>
			<el-form-item label="成员类型" required>
				<el-select   style="width: 100% !important;" v-model="roleId"  placeholder="请选择">
					<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>

			</el-form-item>
			<el-form-item label="所属机构">
			<el-select  style="width: 100% !important;" v-model="returnArray" filterable placeholder="请选择">
				<el-option v-for="item in departAll" :key="item.id" :label="item.fullName" :value="item.id">
				</el-option>
			</el-select>
			</el-form-item>
			
		</el-form>
		<span slot="footer" class="dialog-footer">
				<p style="position:absolute; left:20px;bottom:35px;color: #999999;">新添加账号默认密码为手机号后六位</p>
			<el-button @click="cancel()">取消</el-button>
			<el-button type="primary" @click="dataFormSubmit()">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
	import {
		treeDataTranslate
	} from '@/utils'
	import Qs from 'qs'
	import api from '@/resource/api'
	import SelectTree from './select-tree'
	import {
		isEmail,
		isMobile
	} from '@/utils/validate'
	export default {
		components: {
			SelectTree
		},
		props: ["myData"],
		data() {
			var validateRole = (rule, value, callback) => {
				if (this.roleId == '') {
					callback(new Error('角色不能为空'))
				} else {
					callback()
				}
			}
			var validateName = (rule, value, callback) => {
				if (value == '') {
					callback(new Error('姓名不能为空'))
				} else {
					callback()
				}
			}
			var validateDep = (rule, value, callback) => {
				if (value == '') {
					callback(new Error('部门不能为空'))
				} else {
					callback()
				}
			}

			var validateMobile = (rule, value, callback) => {
				if (value == '') {
					callback(new Error('手机号不能为空'))
				} else if (!isMobile(value)) {
					callback(new Error('手机号格式错误'))
				} else {
					callback()
				}
			}
			var validateIdCard = (rule, value, callback) => {
				if (this.accountData.idCard.length == 0) {
					callback(new Error('身份证号不能为空'))
				} else {
					callback()
				}
			}
			var validateCode = (rule, value, callback) => {
				if (!value) {
					callback(new Error('请输入图形验证码'));
				}
			}
			return {
				accountData: this.myData.user,
				dataArr: '',
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				subjectArr:[],
				returnArray: '', //选择部门
				defaultExpandKeys: [], //默认展开节点列表
				dialogVisible: false,
				departAll: '',
				locationIds:[],
				options: [],
				roleId: "",
				deptId: "",
				dataRule: {
					name: [{
						validator: validateName,
						trigger: 'blur'
					}],
					role: [{
						validator: validateRole,
						trigger: 'blur'
					}],
					department: [{
						validator: validateDep,
						trigger: 'blur'
					}],
					idCard: [{
						validator: validateIdCard,
						trigger: 'blur'
					}],
					phone: [{
						validator: validateMobile,
						trigger: 'blur'
					}]
				}
			}
		},
		computed: {
			deptList: {
				get() {
					return this.$store.state.common.deptList
				},
				set(val) {
					this.$store.commit('common/updateDeptList', val)
				}
			},
			roleList: {
				get() {
					return this.$store.state.common.roleList
				},
				set(val) {
					this.$store.commit('common/updateRoleList', val)
				}
			},
		},
		mounted() {
			
		},
		methods: {
			init(id) {
				console.log('123')
				this.dialogVisible = true
				this.roleId = this.myData.listRole[0]
			this.returnArray = this.myData.user.deptId
				
				this.getRoleList()
				this.getDeptList()
		
			},
			cancel() {
				this.dialogVisible = false;
				this.$emit('refreshDataList', '')
			},
			reset(){
				
this.$refs[accountData].resetFields();
			},
			// 获取角色列表
			getRoleList(data) {

				this.options = this.roleList

			},
		
			// 获取部门列表
			getDeptList() {
				
				
				this.$httpAes({
					url: this.$httpAes.adornUrl('/dept/list'),
					method: 'post',
					data:{},
						contentType:'json',
						headers: {
							'Content-Type': 'application/json',
						   				
						},
				}).then(({
					data
				}) => {
					let myData = data.data
					for (var i = 0; i < myData.length; i++) {
						myData[i].isEdit = false;
						myData[i].children = [];
					}
			
				//	let tree = treeDataTranslate(myData)
						
				//	this.departAll = JSON.parse(JSON.stringify(tree)) 
				this.departAll = myData
					this.maxexpandId = myData[myData.length - 1].id
					this.non_maxexpandId = myData[myData.length - 1].id
					this.num = myData[myData.length - 1].num
				})
			
			
			},

			// 表单提交
			dataFormSubmit() {
				if(this.accountData.userName==''){
					this.$message.error('请填写姓名')
					return
				}
				if(this.accountData.mobile==''){
					this.$message.error('请填写手机号')
					return
				}
				if(this.roleId.length==0){
					this.$message.error('请填选择角色')
					return
				}
				this.accountData.dept = this.returnArray
		
				this.$refs['accountData'].validate((valid) => {
					if (valid) {
					
						let User = {
							// 'userId':this.$cookie.get('userId'),
							'id': this.accountData.id,
							'account': this.accountData.account,
						
							"IdCard": "",
							"email": "",
							"deptId": this.accountData.dept,
							"name": this.accountData.name,
							"phone": this.accountData.phone,
							 "roleId": this.roleId,
						}
						this.dialogVisible = false
					
					

							
							this.$httpAes({
								url: this.$httpAes.adornUrl('user/updateInfo'),
								method: 'post',
								data: User,
									contentType:'json',
									headers: {
										'Content-Type': 'application/json',
									   				
									},
							
							}).then(({
								data
							}) => {
								console.log(data)
								if (data.status) {
									this.$message({
										message: '操作成功',
										type: 'success',
										duration: 1500,
										onClose: () => {
											this.dialogVisible = false
											this.$emit('refreshDataList', '')
										}
									})
								} else {
									this.$message.error(data.msg)
									this.$emit('refreshDataList', '')
								}
							})


					

				}})
			}
		}
	}
</script>
<style>
	.el-dialog {
		width: 800px;
	}
</style>
