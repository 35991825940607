<template>
	<el-dialog title="添加账号"  :close-on-click-modal="false" :visible.sync="dialogVisible">
		<el-form :model="dataForm" class="form-con-center4" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="85px">
	
			<el-form-item label="姓名"  required>
				<el-input v-model="dataForm.userName"></el-input>
			</el-form-item>
			<el-form-item label="手机号"  required>
				<el-input v-model="dataForm.mobile"></el-input>
			</el-form-item>
			<el-form-item label="成员类型"  required>
				<el-select  style="width: 100% !important;" v-model="roleId" filterable placeholder="请选择">
					<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="所属机构"  required>
				<el-select  style="width: 100% !important;" v-model="returnArray" filterable placeholder="请选择">
					<el-option v-for="item in departAll" :key="item.id" :label="item.fullName" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
	
			<el-form-item label="状态"  required>
				<el-radio v-model="dataForm.status" :label="1">启用</el-radio>
				  <el-radio v-model="dataForm.status" :label="2">禁用</el-radio>
			</el-form-item>
		
		</el-form>
		<span slot="footer" class="dialog-footer">
			<p style="position:absolute; left:20px;bottom:35px;color: #999999;">新添加账号默认密码为手机号后六位</p>
			<el-button @click="dialogVisible = false">取消</el-button>
			<el-button type="primary" @click="dataFormSubmit()">确定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import {
		treeDataTranslate
	} from '@/utils'
	import Qs from 'qs'
	import api from '@/resource/api'
	import SelectTree from './select-tree'
	import {
		isEmail,
		isMobile
	} from '@/utils/validate'
	export default {
		components: {
			SelectTree
		},
		data() {
			var validateIdCard = (rule, value, callback) => {
				if (this.dataForm.IdCard.length == 0) {
					callback(new Error('身份证号不能为空'))
				} else {
					callback()
				}
			}
			var validateAccount = (rule, value, callback) => {
				if (this.dataForm.account.length == 0) {
					callback(new Error('账号不能为空'))
				} else {
					callback()
				}
			}
			var validateRole = (rule, value, callback) => {
				console.log(this.roleId)
				if (this.roleId.length==0) {
					callback(new Error('角色不能为空'))
				} else {
					callback()
				}
			}
			var validateName = (rule, value, callback) => {
				if (value == '') {
					callback(new Error('姓名不能为空'))
				} else {
					callback()
				}
			}
		
			var validateMobile = (rule, value, callback) => {
				if (value == '') {
					callback(new Error('手机号不能为空'))
				} else if (!isMobile(value)) {
					callback(new Error('手机号格式错误'))
				} else {
					callback()
				}
			}
			return {
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				subjectArr:[],
				returnArray: [], //选择部门
				defaultExpandKeys: [], //默认展开节点列表
				departAll: [], //节点树数据
				maxexpandId: '', //新增节点开始id
				non_maxexpandId: '', //新增节点开始id(不更改)
				num: '', //排序
				roleId: '',
				options: [],
				dialogVisible: false,
				dataForm: {
					"userId": 1,
					"userName": "",
					"mobile": "",
					"role": '',
					"roleId":'',
					"department": '',
					"IdCard": '',
					"dept":'',
					"createTime": "",
					"account": "",
					"password": "",
					status:1,
				},
				dataRule: {
					userName: [{
						validator: validateName,
						trigger: 'blur'
					}],
					account: [{
						validator: validateAccount,
						trigger: 'blur'
					}],
					IdCard: [{
						validator: validateIdCard,
						trigger: 'blur'
					}],
					role: [{
						validator: validateRole,
						trigger: 'blur'
					}],

					mobile: [{
						validator: validateMobile,
						trigger: 'blur'
					}]
				}
			}
		},
		computed: {
			roleList: {
				get() {
					return this.$store.state.common.roleList
				},
				set(val) {
					this.$store.commit('common/updateRoleList', val)
				}
			},
		},
		created() {
			// this.getRoleList()
			// this.getDeptList()
		
		},
		watch: {

		},
		methods: {
			init(id) {
				this.dataForm.id = id || 0
				this.dialogVisible = true
				this.getRoleList()
				this.getDeptList()
			},
			
		
			// 获取角色列表
			getRoleList(data) {

				this.options = this.roleList

			},
			// 获取部门列表
			getDeptList() {
				
				
				this.$httpAes({
					url: this.$httpAes.adornUrl('/dept/list'),
					method: 'post',
					data:{},
						contentType:'json',
						headers: {
							'Content-Type': 'application/json',
						   				
						},
				}).then(({
					data
				}) => {
					let myData = data.data
					for (var i = 0; i < myData.length; i++) {
						myData[i].isEdit = false;
						myData[i].children = [];
					}

				//	let tree = treeDataTranslate(myData)
						
				//	this.departAll = JSON.parse(JSON.stringify(tree)) 
				this.departAll = myData
					this.maxexpandId = myData[myData.length - 1].id
					this.non_maxexpandId = myData[myData.length - 1].id
					this.num = myData[myData.length - 1].num
				})


			},

			// 表单提交
			dataFormSubmit() {
				this.dataForm.dept = this.returnArray
				if(this.dataForm.userName==''){
					this.$message.error('请填写姓名')
					return
				}
				if(this.dataForm.mobile==''){
					this.$message.error('请填写手机号')
					return
				}
				if(this.roleId.length==''){
					this.$message.error('请填选择角色')
					return
				}
				this.$refs['dataForm'].validate((valid) => {
					if (valid) {
						
				
										 
										let User = {
											// 'userId':this.$cookie.get('userId'),
											'account': this.dataForm.mobile,
											'password': this.dataForm.mobile.substring(this.dataForm.mobile.length - 6),
									
											roleId:this.roleId,
						// 					"IdCard": "",
						// 					"avatar": "",
											"status": "1",
											"deptId": this.dataForm.dept,
											"name": this.dataForm.userName,
											"phone": this.dataForm.mobile,
										}
						this.dialogVisible = false
						this.$emit('refreshDataList', this.dataForm)
						this.$httpAes({
							url: this.$httpAes.adornUrl('user/add'),
							method: 'post',
							data: User,
								contentType:'json',
								headers: {
									'Content-Type': 'application/json',
								   				
								},
						
						}).then(({
							data
						}) => {
							if (data.status) {
								this.$message({
									message: '操作成功',
									type: 'success',
									duration: 1500,
									onClose: () => {
										this.dialogVisible = false
										this.$emit('refreshDataList')
									}
								})
							} else {
								this.$message.error(data.msg)
							}
						})
					}
				})
			}
		}
	}
</script>
<style  scoped  lang="scss">
	.el-dialog {
		width: 800px;
	}
</style>
