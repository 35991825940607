<template>
  <el-popover placement="bottom-start" width="480" @hide="popoverHide" trigger="click" v-model="isShowSelect">
    <el-tree
      style="overflow-y: scroll;"
      v-if="isShowSelect"
      :data="treeData"
      :check-strictly="true"
      :node-key="nodeKey"
      :show-checkbox="multiple"
      :expand-on-click-node="multiple"
      :default-expanded-keys="defaultExpandedKeys"
      :default-checked-keys="defaultCheckedKeys"
      :default-expand-all="defaultExpandAll"
      highlight-current
      @node-click="handleNodeClick"
      @check="getKeys"
      :props="defaultProps"
    ></el-tree>
    <el-select style="width: 100% !important;" slot="reference" ref="select" v-model="showDeptName" size="small" :clearable="true" :multiple="multiple" :placeholder="tipText" @click.native="isShowSelect = !isShowSelect">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
  </el-popover>
</template>
<script>
import { isNull } from '@/utils/validate'
export default {
  props: {
    treeData: [String, Array,Number],
		deptName:[String],
		dept: { type: Array},
    defaultExpandAll: {
      type: Boolean,
      default: true
    },
    popoverHide: {
      type: Function,
      default: function () { }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    id: [String, Array,Number],
    nodeKey: { type: String, default: 'id' },
    tipText: { type: String, default: '请选择' }
  },
  data () {
    return {
      // 是否显示树状选择器
      isShowSelect: false,
      options: [],
      key: [],
			showDeptName:this.deptName,
      showValueTmp: '',
      defaultExpandedKeys: [],
      defaultCheckedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'simpleName'
      },
    }
  },
  watch: {
    isShowSelect (val) {
      // 隐藏select自带的下拉框
      this.$refs.select.blur()
      if (val) {
        // 下拉面板展开-选中节点-展开节点
        this.setTreeCheckNode(this.key)
        this.defaultCheckedKeys = this.key
        this.defaultExpandedKeys = this.key
      }
    },
    key (val) {
		
      if (this.multiple) {
        this.$emit('update:id', this.key)
      } else {
        this.$emit('update:id', this.key[0])
      }
    }
  },
  mounted () {
    // 把传进来的参数转成数组处理
    if (this.multiple && Array.isArray(this.id)) {
      this.key = this.id
    } else {
      this.key.push(this.id)
    }
    this.setTreeCheckNode(this.key)
// 		 let tmpMap = {}
// 		tmpMap.value = '6ff9ee297ae8402d9109'
// 		tmpMap.label = '丹阳市高薪创业服务有限公司'
// 		this.options = []
// 		this.options.push(tmpMap)
// 		this.key = ['6ff9ee297ae8402d9109']
// 		this.showDeptName = '丹阳市高薪创业服务有限公司'
		// this.showVal = [data.simpleName]
		// this.isShowSelect = !this.isShowSelect
  },

	
  methods: {
    handleNodeClick (data) {
		this.showDeptName = ""
      if (!this.multiple) {
        let tmpMap = {}
        tmpMap.value = data.id
        tmpMap.label = data.simpleName
        this.options = []
        this.options.push(tmpMap)
        this.key = [data.id]
				this.showDeptName = data.simpleName
				// this.showVal = [data.simpleName]
        this.isShowSelect = !this.isShowSelect
      }
    },
    getKeys (data, checked) {
		
      let tmp = []
      checked.checkedNodes.forEach(node => {
        let tmpMap = {}
        tmpMap.value = node.id
        tmpMap.label = node.simpleName
        tmp.push(tmpMap)
      })
      this.options = tmp
      this.key = checked.checkedKeys
    },
    setTreeCheckNode (ids) {
      let tmp = []
      ids.forEach(id => {
        this.findTreeNode(this.treeData, id)
        tmp.push(this.showValueTmp)
      })
    },
    // 递归查询树形节点
    findTreeNode (tree, id) {
      if ((!isNull(tree)) && (!isNull(id))) {
        for (var i = 0; i < tree.length; i++) {
          if (tree[i].id === id) {
            this.showValueTmp = tree[i].label
          } else if (tree[i].children != null && tree[i].children.length > 0) {
            this.findTreeNode(tree[i].children, id)
          }
        }
      }
    }
  }
}
</script>
